<template>
  <v-row>
    <v-col cols="12">
      <div class="px-1 mb-3">
        <span class="text-h2 cassiopee-purple--text"> 
          {{ subtitle }} 
        </span>
        <base-commentaire v-if="commentaire">
          {{ commentaire }}
        </base-commentaire>
      </div>
      <v-divider 
        v-if="divider" 
        class="cassiopee-purple" 
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseCommentaire from '@/components/BaseCommentaire.vue'
export default {
  name: "BaseSubtitle",
  components: {
    BaseCommentaire
  },
  props: {
    subtitle: {
      type: String,
      required: true
    },
    divider: {
      type: Boolean,
      default: false
    },
    commentaire: {
      type: String,
      default: ""
    },
  }
}
</script>
