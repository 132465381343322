<template>
  <div>
    <v-btn
      class="form-button-add-element my-2 px-6 py-4"
      :class="{'btn-littleSize': littleSize, 'full-color': !outlined}"
      depressed
      :outlined="outlined"
      color="cassiopee-purple"
      height="50px"
      :width="width"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-icon 
        left
        class="mx-4"
        :color="iconColor"
      >
        $additionnal
      </v-icon>
      <span 
        class="text-button"
        :class="{
          'font-littleSize': littleSize, 
          'white--text': !outlined, 
          'cassiopee-purple--text': outlined
        }"
      >
        {{ label }}
      </span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "FormButtonAddElement",
  props: {
    littleSize: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: "440px"
    },
    iconColor: {
      type: String,
      default: undefined
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.form-button-add-element {
  background-color:  map-deep-get($colors, 'cassiopee-purple', 'lighten-1') !important;

  &.full-color {
    background-color:  map-deep-get($colors, 'cassiopee-purple', 'base') !important;
  }
}
</style>