<template>
  <div class="ma-10">
    <v-form>
      <v-row>
        <v-col cols="12">
          <form-step-title title="Stratégie à 5 ans" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <form-fields-multi-select
            :value="nouveau_service_attendus.map(element => element.nom)"
            field-label="Quels sont les nouveaux services attendus dans les prochaines années ?"
            label="Liste des services" 
            :items="nouveau_service_attendus_liste"
            max-length
            multiple
            field-description="Sélectionner les services que vous envisagez de mettre en place ou de rechercher chez un prestaire par ordre de priorité."
            counter="5"
            by-order
            class="mt-3 mb-2"
            @input="updateNouveauServiceAttendus($event)"
          />
          <v-row
            v-for="(currentOtherService, index) in nouveauServiceAttendusFormAnswer"
            :key="index"
            class="my-2"
          >
            <v-col class="d-flex justify-end pr-0">
              <form-fields-text
                v-model="currentOtherService.nom_autre" 
                field-label="Pouvez-vous préciser quels sont les nouveaux services attendus dans les prochaines années" 
                placeholder="Précisez quel est le nouveau service attendu"
                @blur="saveFormAnswer"
              />
              <base-button-icon 
                icon="$trashPurple"
                @click="deleteCurrentOtherService(currentOtherService)"
              />
            </v-col>
          </v-row>
          <v-row v-if="nouveauServiceAttendusFormAnswer.length && nouveau_service_attendus.length < 5">
            <v-col cols="12">
              <div class="d-flex justify-end">
                <form-button-add-element
                  class="d-flex justify-end mb-5"
                  label="Ajouter un service"
                  outlined
                  @click="addOtherService"
                />
              </div>
            </v-col>
          </v-row>
          <base-subtitle subtitle="Transformations des offres, Evolution des services SI" />
          <form-fields-radio-group 
            v-model="strategie_long_terme_transformation_offres_choice"
            field-label="Envisagez-vous de transformer vos offres ?" 
            hint="Envisagez-vous de faire évoluer les services que vous proposez à vos utilisateurs / aux métiers ?"
            persistent-hint
            :radio-data-list="booleanList"
            simple-radio-button
            @change="saveFormAnswer"
          />
          <form-fields-textarea
            v-if="strategie_long_terme_transformation_offres_choice === 'Oui'"
            v-model="strategie_long_terme_detail_transformation_offre"
            field-label="Pouvez-vous préciser comment vous envisagez de tranformer vos offres ?" 
            placeholder="Précisez comment vous envisagez de tranformer vos offres"
            @change="saveFormAnswer"
          />
          <base-subtitle subtitle="Transformation du delivery model" />
          <form-fields-radio-group 
            v-model="strategie_long_terme_transformation_delivery_model_choice"
            field-label="Envisagez-vous de transformer la façon de délivrer vos services IT ?" 
            :radio-data-list="booleanList"
            simple-radio-button
            @change="saveFormAnswer"
          />
          <form-fields-textarea 
            v-if="strategie_long_terme_transformation_delivery_model_choice === 'Oui'"
            v-model="strategie_long_terme_detail_transformation_delivery_model"
            field-label="Pouvez-vous préciser comment vous envisagez de tranformer la façon de délivrer vos services IT" 
            placeholder="Précisez comment vous envisagez de tranformer la façon de délivrer vos services IT"
            @change="saveFormAnswer"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import FormStepTitle from '@/components/FormStepTitle.vue'
import FormFieldsMultiSelect from '@/components/FormFieldsMultiSelect.vue'
import FormFieldsRadioGroup from '@/components/FormFieldsRadioGroup.vue'
import FormFieldsTextarea from '@/components/FormFieldsTextarea.vue'
import { mapState, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import BaseButtonIcon from '@/components/BaseButtonIcon.vue'
import FormFieldsText from '@/components/FormFieldsText.vue'
import FormButtonAddElement from '@/components/FormButtonAddElement.vue'
import BaseSubtitle from '@/components/BaseSubtitle.vue'


export default {
  name: "Impact",
  components: {
    FormStepTitle,
    FormFieldsMultiSelect,
    FormFieldsRadioGroup,
    FormFieldsTextarea,
    BaseButtonIcon,
    FormFieldsText,
    FormButtonAddElement,
    BaseSubtitle
  },
  data () {
    return {
      otherServices: "",
      booleanList: [
        {
          label: "Oui",
          value: "Oui"
        },
        {
          label: "Non",
          value: "Non"
        },
        {
          label: "Ne sait pas",
          value: "Ne sait pas"
        }
      ],
    }
  },
  computed: {
    ...mapState('form', ['nouveau_service_attendus_liste']),
    ...mapFields('form', [
      'formAnswer.nouveau_service_attendus',
      'formAnswer.strategie_long_terme_transformation_offres_choice',
      'formAnswer.strategie_long_terme_transformation_delivery_model_choice',
      'formAnswer.strategie_long_terme_detail_transformation_offre',
      'formAnswer.strategie_long_terme_detail_transformation_delivery_model',
    ]),
    nouveauServiceAttendusFormAnswer() {
      return this.nouveau_service_attendus.filter(nouveauService => nouveauService.nom == 'Autres services');
    },
  },
  created() {
    this.fetchNouveauServiceAttendus();
    this.fetchFormAnswer();
    this.setNextView('/strategy/impact');
  },
  methods: {
    ...mapMutations('stepper', ['setNextView']),
    ...mapActions('form', [
      'fetchNouveauServiceAttendus',
      'fetchFormAnswer',
      'saveFormAnswer',
      'updateNouveauServiceAttendus',
      'deleteCurrentOtherService',
      'addOtherService'
    ]),
  },
}
</script>