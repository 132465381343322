<template>
  <base-form-field
    :required="required"
    :label="fieldLabel"
  >
    <v-select
      v-if="byOrder"
      v-bind="$attrs"
      outlined
      color="cassiopee-purple"
      item-color="cassiopee-purple"
      :menu-props="{bottom: true, offsetY: true, maxHeight: '400'}"
      class="form-select"
      :hint="fieldDescription"
      :rules="selectedRules"
      :persistent-hint="true"
      :success="validateValue"
      v-on="$listeners"
    >
      <template
        slot="selection" 
        slot-scope="data" 
      >
        {{ data.index + 1 }}. {{ data.item.nom || data.item }},
      </template>
    </v-select>

    <v-select
      v-else
      v-bind="$attrs"
      outlined
      color="cassiopee-purple"
      item-color="cassiopee-purple"
      :menu-props="{bottom: true, offsetY: true, maxHeight: '400'}"
      class="form-select"
      :hint="fieldDescription"
      :rules="selectedRules"
      :persistent-hint="true"
      :success="validateValue"
      v-on="$listeners"
    />
  </base-form-field>
</template>

<script>
import BaseFormField from "./BaseFormField.vue"
import validateField from "@/mixins/validateField.js"

export default {
  name: "FormFieldsMultiSelect",
    components: {
    BaseFormField,
  },
  mixins: [validateField],
  props: {
    fieldLabel: {
      type: String,
      default: ""
    },
    fieldDescription: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    byOrder: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/variables';

.form-select {
  z-index: 10;

  .v-select__selection {
    font-size: 13px;
  }

  .v-label {
    font-size: 13px;
  }
}

.v-list .v-list-item .v-list-item__title {
  color: map-deep-get($colors, 'cassiopee-purple', 'base') !important;
  font-size: 13px;
}
</style>